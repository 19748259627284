import {
  Bookmark,
  CreditCard,
  DollarSign,
  Edit,
  Gift,
  Layout,
} from "react-feather"
import greenPlant from "./assets/img/green-plant.jpg"

const company = {
  street: "",
  city: "",
  state: "",
  zip: "",
  name: "Alpha Beta Software",
  slogan: "We make custom web applications and websites",
  missionStatement: "Our Services",
  email: "admin@alphabetasoftware.com",
}
const iconSize = 20
const services = [
  {
    title: "Website",
    description: "We make fullstack custom websites",
    moreInfoLink: "/website",
    icon: <Layout className={`w-${iconSize} h-${iconSize}`} />,
  },
  {
    title: "Payments",
    description: "Need to accept payments? We can help with that.",
    moreInfoLink: "/backend",
    icon: <DollarSign className={`w-${iconSize} h-${iconSize}`} />,
  },
  {
    title: "Custom",
    description:
      "Need something custom like a Web App or a component? We can help with this too!",
    moreInfoLink: "/custom",
    icon: <Gift className={`w-${iconSize} h-${iconSize}`} />,
  },
]

const bigFeatureText = "Get your web presence up in just a day!"

const pricing = {
  title: "Pricing",
  subtitle: "Lets Break the Ice",
  tiers: [
    {
      name: "static",
      price: "$400",
      features: ["Custom Website"],
      url: "/static-site",
    },
    {
      name: "e-commerce",
      price: "$500",
      features: ["Everything in START", "Accept Payments", "Blog"],
      url: "/e-commerce",
    },
    {
      name: "custom",
      price: "$600+",
      features: ["Anything Custom", "Website or web app"],
      url: "/custom",
    },
  ],
  moreInfo: [
    {
      name: "Static",
      description: `Sometimes you just want a website that exists that doesn't do much. 
      It's just there for people to see;
      it's there so people know where your business is located.`,
      steps: [
        {
          description:
            "We meet with our clients and get a feel for what they want in a website. Talk about costs etc.",
        },
        {
          description: "Make the first draft",
        },
        {
          description: `Second meeting with our client. `,
        },
        {
          description: `Make the second draft`,
        },
        {
          description:
            "Publish your website, so you can get started with your business.",
        },
        {
          description: `Third meeting with our client`,
        },

        {
          description: `Finishing touches`,
        },
        {
          description: `Publish the final website`,
        },
      ],
      icon: (
        <Layout className="lg:w-2/6 md:w-3/6 w-5/6 mb-10 w-40 h-40 bg-green-100 p-6 object-cover object-center rounded-full shadow-lg" />
      ),
    },
    {
      name: "E-Commerce",
      description: `Sometimes you want just have the itch to sell something; 
      Something handmade or some service that you can do etc. You have a lot of products that need to be sold, or a perhaps you have a few. `,
      steps: [
        {
          description:
            "We meet with our clients and get a feel for what they want in a website. Talk about costs etc.",
        },
        {
          description: "Make the first draft",
        },
        {
          description: `Second meeting with our client. `,
        },
        {
          description: `Make the second draft`,
        },
        {
          description:
            "Publish your website, so you can get started with your business.",
        },
        {
          description:
            "We integrate your site with backend code. This handles things like payment processing, authorization,admin etc. ",
          color: "bg-gray-200",
        },
        {
          description: `Make backend code if needed. This handles things like payment processing, login, admin, etc.`,
        },
        {
          description: `Third meeting with our client`,
        },

        {
          description: `Finishing touches`,
        },
        {
          description: `Publish the final website`,
        },
      ],
      icon: (
        <DollarSign className="lg:w-2/6 md:w-3/6 w-5/6 mb-10 w-40 h-40 bg-green-100 p-6 object-cover object-center rounded-full shadow-lg" />
      ),
    },
    {
      name: "Custom",
      description: `Let's say you need us to build you something interactive like a scheduler for your website. 
      Usually custom work involves something interactive. 
      Another example is where you need an web application. Usually it solves a business problem.
      These kind of software systems can be complex and take some time to complete, but it's nothing we can't handle.`,
      steps: [
        {
          description:
            "Meet with our clients and find out what custom work is needed in their website or what app should we build.",
        },
        {
          description:
            "Determine if the custom work is an app or extra work in a website",
          color: "bg-gray-200",
        },
        {
          description: "Make a mockup",
        },
        {
          description: "Second meething with client",
        },
        {
          description: "Make UI for the custom item or app",
        },
        {
          description: `Write server logic for the custom item or app`,
        },
        {
          description: `Third meeting with our client`,
        },
        {
          description: `Finishing touches`,
        },
        {
          description: `Publish the final app/website`,
        },
      ],
      icon: (
        <DollarSign className="sm:text-left text-center lg:w-2/6 md:w-3/6 w-5/6 mb-10 w-40 h-40 bg-green-100 p-6 object-cover object-center rounded-full shadow-lg " />
      ),
    },
  ],
  moreInfoFinish: {},
}

const FAQ = [
  {
    question: "Do you do mobile websites?",
    answer: "All the websites we build are mobile ready.",
  },
  {
    question: "Do you offer discounts?",
    answer:
      "No. Our services are discounted already. Websites usually cost $2k+.",
  },
  {
    question: "What payments do you accept?",
    answer: "We accept most credit cards ",
  },
  {
    question: "Are my credit/debit cards secure on your website?",
    answer: `We do not store your credit card information on our servers. 
      We use a PCI compliant company called Stripe.We enjoy their service because they keep our users' information secure.
      We also use encryption on our website and our customers' websites for extra protection. `,
  },
]

const about = {
  whoWeAre: {
    subtitle:
      " We make custom web software for clients and consumers and are a freelance company.",
    body: (
      <p>
        We are a <b>freelance</b> company that loves to make <b>web apps</b> and
        <b> websites</b> for clients. Although we may not be able to get to and
        please everyone we try our hardest to help and do the <b>best</b> job
        possible, <b>or your money back.</b> <u>That's our promise.</u>
      </p>
    ),
  },
  questions: {
    email: company.email,
  },
  moreInfo: {
    title: "Fullstack Websites",
    subtitle: " We make custom web fullstack websites for our customers",
    body: (
      <>
        We make fullstack websites, that means both what you see({" "}
        <b>front end</b>), and what makes the website work ( <b>backend</b>). We
        use various tech platforms to make the website secure and safe for all.
        For payments we use a PCI compliant company called Stripe
      </>
    ),
  },
}

const projects = [
  {
    name: "Functional Healing Solution",
    subtitle: "",
    description: "A traveling RN that gives botox injections",
    image: greenPlant,
    link: "functionalhealingsolution.com",
  },
]

const userDashboard = {
  menuItems: [
    ["Start an order", <Edit />, "/order"],
    ["Wallet", <CreditCard />, "/wallet"],
    ["Invoices", <Bookmark />, "/invoices"],
  ],
}

const main = {
  services,
  pricing,
  projects,
  company,
  FAQ,
  userDashboard,
  bigFeatureText,
  about,
}

export default main
