import React from 'react';
import Loader from "react-loader-spinner";

const Loading = () => {
    return (<div className="absolute inset-2/4 z-40 ">
        <Loader
        type="ThreeDots"
        color="#00BFFF"
        height={100}
        width={100}
        timeout={3000} //3 secs
      />
    </div> );
}
 
export default Loading;