import React from "react"
import vars from "../../vars"

const Gallery = () => {
  // const item = (x) => {
  //   return (
  //     <div classNameName="lg:w-1/3 sm:w-1/2 p-4">
  //       <div classNameName="flex relative ">
  //         <img
  //           alt="gallery"
  //           classNameName="absolute inset-0 w-full h-full object-cover object-center"
  //           src="https://dummyimage.com/600x360"
  //         />
  //         <div classNameName="px-8 py-10 relative z-10 w-full border-4 border-gray-200 bg-white opacity-0 hover:opacity-100 ">
  //           <h2 classNameName="tracking-widest text-sm title-font font-medium text-indigo-500 mb-1">
  //             {x.name}
  //           </h2>
  //           <h1 classNameName="title-font text-lg font-medium text-gray-900 mb-3">
  //             {x.subtitle}
  //           </h1>
  //           <p classNameName="leading-relaxed">{x.description}</p>
  //         </div>
  //       </div>
  //     </div>
  //   )
  // }
  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 py-24 mx-auto">
        <div className="flex flex-col text-center w-full mb-20">
          <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">
            Our Projects
          </h1>
          {/* <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
           We are proud of these
          </p> */}
        </div>
        <div className="flex flex-wrap -m-4">
          {vars.projects.map((x, i) => (
            <a key={`gallery-${i}`} href="https://functionalhealingsolution.com">
              <div>
                <div className="flex relative">
                  <img
                    alt="gallery"
                    className="absolute inset-0 w-full h-full object-cover object-center rounded-lg "
                    src={x.image}
                  />
                  <div className="px-8 py-10 relative z-10 w-full  bg-white opacity-0 hover:opacity-70  ">
                    <h2 className="tracking-widest text-sm title-font font-medium text-indigo-500 mb-1">
                      {x.subtitle}
                    </h2>
                    <h1 className="title-font text-lg font-medium text-gray-900 mb-3">
                      {x.name}
                    </h1>
                    <p className="leading-relaxed">{x.description}</p>
                  </div>
                </div>
              </div>
            </a>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Gallery
