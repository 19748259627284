import products from './products'
import card     from './card'
import balance  from "./balance"
import invoices from "./invoices"



const main = {
    products,
    card,
    balance,
    invoices
}
export default main