import { CardElement } from "@stripe/react-stripe-js"
import axios from "axios"
import React, { useContext } from "react"
import { deleteCard, getAllPaymentMethods } from "../../../../API/stripe/card"
import CreditCardItem from "../../../../components/CreditCardItem"
import { UserDashboardContext } from "../../../../contexts/UserDashboardState"
import axiosSource from "../../../../helpers/axiosHelper"

const CardPage = (props) => {
  const [state, setState] = React.useState({
    loading: true,
    refresh: false,
  })
  const hasFetchedData = React.useRef(false)

  const dashboardContext = useContext(UserDashboardContext)

  const handleStateChange = (e) => {
    const { name, value } = e.target
    dashboardContext.setInfo({ ...dashboardContext.info, [name]: value })
  }

  const handleAddressChange = (e) => {
    const { name, value } = e.target
    const address = dashboardContext.address
    dashboardContext.setAddress({ ...address, [name]: value })
  }

  React.useEffect(() => {
    //get infor from backend and display it
    const source = axiosSource
    const init = async () => {
      try {
        const { data } = await getAllPaymentMethods()
        if (data.paymentMethods) {
          dashboardContext.setPaymentMethods(data.paymentMethods.data)
        }
        setState({ ...state, loading: false })
      } catch (error) {
        console.log(error)
        setState({ ...state, loading: false })
        if(axios.isCancel(error)) return
      }
    }

    if (hasFetchedData.current === false) {
      init()
      hasFetchedData.current =true
    }
    return () => source.cancel()
  }, [dashboardContext,state])

  const handleDeleteCard = async (id) => {
    const temp = dashboardContext?.paymentMethods.filter((x) => x.id !== id)
    dashboardContext.setPaymentMethods(temp)

    await deleteCard(id)
      .then((x) => {
        console.log(x.data.message)
        window.location.reload()
      })
      .catch((error) => {
        console.log(error)
        window.location.reload()
      })
  }

  return (
    <div className="bg-black-400 flex flex-col m-auto max-w-xl  md:m-4 md:p-10 bg-white rounded shadow-xl bg-dashboard bg-contain bg-no-repeat ">
      <div id="payment-methods" className="m-auto p-8">
        <p className="justify-center font-medium text-xl text-white">
          Saved Payment Methods
        </p>
      </div>
      {dashboardContext?.paymentMethods?.length > 0 ? (
        dashboardContext?.paymentMethods?.map((x) => {
          console.log(x)
          return (
            <CreditCardItem
              brand={x?.card?.brand}
              last4={x?.card?.last4}
              exp_year={x?.card?.exp_year}
              exp_month={x?.card?.exp_month}
              name={x?.billing_details?.name}
              id={x?.id}
              handleDeleteCard={(id) => handleDeleteCard(id)}
            />
          )
        })
      ) : (
        <div className="leading-loose flex flex-row flex-wrap">
          <form className="max-w-xl m-4 p-10 bg-white rounded shadow-xl ">
            <p className="text-gray-800 font-medium">Customer information</p>
            <div className="">
              <label className="block text-sm text-gray-00" htmlFor="cus_name">
                Name
              </label>
              <input
                className="w-full px-5 py-1 text-gray-700 bg-gray-200 rounded"
                name="name"
                type="text"
                required=""
                placeholder="Name"
                aria-label="Name"
                autoComplete="name"
                onChange={handleStateChange}
              />
            </div>
            <div className="mt-2">
              <label
                className="block text-sm text-gray-600"
                htmlFor="cus_email"
              >
                Email
              </label>
              <input
                className="w-full px-5  py-4 text-gray-700 bg-gray-200 rounded"
                name="email"
                type="email"
                required=""
                placeholder="Email"
                aria-label="Email"
                autoComplete="email"
                onChange={handleStateChange}
              />
            </div>
            <div className="mt-2">
              <input
                className="w-full px-5  py-4 text-gray-700 bg-gray-200 rounded"
                name="phone"
                type="tel"
                required={true}
                placeholder="Phone"
                aria-label="phone"
                autoComplete="phone"
                onChange={handleStateChange}
              />
            </div>
            <div className="mt-2">
              <label
                className=" block text-sm text-gray-600"
                htmlFor="cus_email"
              >
                Address
              </label>
              <input
                className="w-full px-2 py-2 text-gray-700 bg-gray-200 rounded"
                name="street"
                type="address"
                required=""
                placeholder="Street"
                aria-label="Street Address"
                autoComplete="home street-address"
                onChange={handleAddressChange}
              />
            </div>
            <div className="mt-2">
              <label
                className="hidden text-sm block text-gray-600"
                htmlFor="city"
              >
                City
              </label>
              <input
                className="w-full px-2 py-2 text-gray-700 bg-gray-200 rounded"
                name="city"
                type="text"
                required=""
                placeholder="City"
                aria-label="city"
                autoComplete="home city"
                onChange={handleAddressChange}
              />
            </div>
            <div className="inline-block mt-2 w-1/2 pr-1">
              <label
                className="hidden block text-sm text-gray-600"
                htmlFor="country"
              >
                Country
              </label>
              <input
                className="w-full px-2 py-2 text-gray-700 bg-gray-200 rounded"
                name="country"
                type="text"
                required=""
                placeholder="Country"
                aria-label="home country"
                autoComplete="home country"
                onChange={handleAddressChange}
                maxLength={2}
              />
            </div>
            <div className="inline-block mt-2 -mx-1 pl-1 w-1/2">
              <label
                className="hidden block text-sm text-gray-600"
                htmlFor="zip"
              >
                Zip
              </label>
              <input
                className="w-full px-2 py-2 text-gray-700 bg-gray-200 rounded"
                name="zip"
                type="text"
                required=""
                placeholder="Zip"
                aria-label="Email"
                autoComplete="home zip"
                onChange={handleAddressChange}
                maxLength={5}
              />
            </div>

            <div className="max-w-xl m-4 p-10 bg-white rounded shadow-xl">
              <p className="mt-4 text-gray-800 font-medium">
                Payment information
              </p>
              <CardElement />
            </div>
            <div>
              <p className="text-xs p-2 text-gray-400">
                Let's make things less complicated, less wordy and more
                transparent. Your card will be stored on secure bank services.
                The company we use is Stripe. We love them because they keep
                your card information secure. We won't charge your card unless
                you opt to use our services. Thank you!
              </p>
            </div>
            <div className="mt-4 flex items-center justify-center">
              <button
                className="px-4 py-1 text-white font-light tracking-wider bg-green-500 rounded"
                type="submit"
                onClick={dashboardContext.handleSubmit}
              >
                Update
              </button>
            </div>
          </form>
        </div>
      )}
    </div>
  )
}

export default CardPage
