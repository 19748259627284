//invoices
import axios      from "axios"

import { isAuth } from "../Auth/email"

const base = `${process.env.REACT_APP_API}/stripe/invoices`

/**
 * @description saves the user's card but only saves 1 card | cardNumber,name, expiry,cvc
 * @param {Object} card
 */
export const getCustomerInvoices = async () => {
  const url = `${base}/get-customer-invoices`
  const jwt = isAuth()
  return await axios.post(
    url,
    {},
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      withCredentials: true,
    }
  )
}

export const pay = async (invoiceId) => {
  const url = `${base}/pay-invoice`
  const jwt = isAuth()
  return await axios.post(
    url,
    { invoiceId },
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      withCredentials: true,
    }
  )
}

const main = {
  getCustomerInvoices,
  pay,
}

export default main
