import React from "react"
import Profile from "../../../Profile"
const DashboardHome = () => {
  return (
    <div>
      <Profile />
    </div>
  )
}

export default DashboardHome
