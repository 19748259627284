import React from "react"
import vars from "../../vars"
const Features = () => {
  return (
    <section className="text-gray-600 body-font">
      <div className="container px-5 py-24 mx-auto">
        <div className="text-center mb-20">
          <h1 className="sm:text-3xl text-2xl font-medium title-font text-gray-900 mb-4">
            Services
          </h1>
          <p className="text-base leading-relaxed xl:w-2/4 lg:w-3/4 mx-auto text-gray-500s">
            {vars.missionStatement}
          </p>
          <div className="flex mt-6 justify-center">
            <div className="w-16 h-1 rounded-full bg-gray-500 inline-flex"></div>
          </div>
        </div>
        <div className="flex flex-wrap sm:-m-4 -mx-4 -mb-10 -mt-4  md:space-y-0 space-y-6 w-full  items-center w-full">
          {/* start */}
          {vars.services.map((x, i) => {
            console.log(x)
            let hash = ""
            if (x.title === "Website") {
              hash = "static"
            }
            if (x.title === "Custom") {
              hash = "custom"
            }
            if (x.title === "Payments") {
              hash ="e-commerce"
            }
            
            return (
              <div
                key={"services" + i}
                className="p-4 md:w-1/3 flex flex-col text-center items-center w-full"
              >
                <div className="w-60 h-60 inline-flex items-center justify-center rounded-full text-green-500 mb-5 flex-shrink-0">
                  {x.icon}
                </div>
                <div className="flex-grow">
                  <h2 className="text-gray-900 text-lg title-font font-medium mb-3">
                    {x.title}
                  </h2>
                  <p className="leading-relaxed text-base">{x.description}</p>
                  <a
                    className="mt-3 text-green-500 inline-flex items-center"
                    href={`/more-info/#${hash}`}
                  >
                    Learn More
                    <svg
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      className="w-4 h-4 ml-2"
                      viewBox="0 0 24 24"
                    >
                      <path d="M5 12h14M12 5l7 7-7 7"></path>
                    </svg>
                  </a>
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div className="flex items-center justify-center p-20 text-4xl w-full bg-gray-100 ">
        {vars.bigFeatureText}
      </div>
    </section>
  )
}

export default Features
