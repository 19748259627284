import React from "react"
import vars from "../../vars"


const FAQ = () => {
  return (
    <section className="text-gray-600 body-font overflow-hidden pt-40">
      <h2 className="text-center text-3xl">Facts and Questions</h2>
      <div className="container px-5 py-24 mx-auto">
        <div className="-my-8 divide-y-2 divide-gray-100">
          {vars.FAQ.map((x, i) => (
            <div key={`faq-item-${i}`} className={`py-8 flex flex-wrap md:flex-nowrap bg-${i%2===0?"white":"gray"}-100 p-2 rounded-xl `}>
              <div className="md:flex-grow">
                <h2 className="text-2xl font-medium text-gray-900 title-font mb-2">
                  {x.question}
                </h2>
                <p className="leading-relaxed">{x.answer}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default FAQ
