import axios from "axios"
import { isAuth } from "../Auth/email"

//http://localhost:8000/api
const base = `${process.env.REACT_APP_API}/orders`



export const start = async (day, time,message,websiteType,phone) => {
	const url = `${base}/start`
	const jwt = isAuth()
	return await axios.post(
		url,
    {
      day, time,message,websiteType,phone
    },
		{
			headers: {
				Authorization: `Bearer ${jwt}`,
			},
			withCredentials: true,
		}
	)
}

const main = {
	start
}
export default main
