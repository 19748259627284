import axios from "axios"
import React, { useEffect } from "react"
import StripeAPI from "../../../API/stripe/index"
import Loading from "../../../components/Loading"
import Invoice from "./Invoice"

const Invoices = () => {
  const [state, setState] = React.useState({
    invoices: [],
    loading: true,
  })
  const hasFetchedData = React.useRef(false)

  useEffect(() => {
    // const source = axiosSource
    const init = async () => {
      await StripeAPI.invoices
        .getCustomerInvoices()
        .then((x) => {
          setState({ ...state, invoices: x.data.invoices.data, loading: false })
        })
        .catch((error) => {
          setState({ ...state, loading: false })
          if (axios.isCancel(error)) return
        })
    }
    // if (hasFetchedData.current === false) {
    //   console.log("loop!")
    //   init()
    //   hasFetchedData.current = true
    // }
    init()
    // return () => {
    //   source.cancel()
    // }
  }, [])
  return (
    <div className="shadow-md flex flex-row gap-10 flex-wrap rounded-full ">
      {state.loading === true ? <Loading /> : null}
      {state.invoices.length > 0 ? (
        state.invoices.map((x, i) => {
          return (
            <Invoice
              amountDue={x.amount_due}
              amountPaid={x.amount_paid}
              description={x.description}
              currency={x.currency}
              total={x.total}
              id={x.id}
              hostedInvoiceUrl={x.hosted_invoice_url}
              lines={x.lines} //arr
            />
          )
        })
      ) : (
        <div className=" text-white w-32 h-20 flex items-center justify-center bg-green-500 rounded-full">
          <p>No Invoices</p>
        </div>
      )}
    </div>
  )
}

export default Invoices
