import React, { useEffect } from "react"
import OrdersAPI from "../../../../API/orders"
import CustomTools from "../../../../assets/img/custom-tools.jpg"
import staticWebsiteImg from "../../../../assets/img/mountains.jpg"
import eCommerceImg from '../../../../assets/img/tools.jpg'
import TierMiniCard from "../../../../components/TierMiniCard"
import { SnackbarContext } from "../../../../contexts/SnackbarState"
import ErrorsHelper from "../../../../helpers/errorsHelper"
const Order = () => {
  const [state, setState] = React.useState({
    websiteType: "",
    day: "",
    time: "",
    message: "",
    phone: "",
  })
  const snackctx = React.useContext(SnackbarContext)

  useEffect(() => {
    console.log(state)
  }, [state])

  const handleSubmit = async (e) => {
    e.preventDefault()
    //send to api
    // alert("sending message")

    await OrdersAPI.start(
      state.day,
      state.time,
      state.message,
      state.websiteType,
      state.phone
    )
      .then((x) => {
        try {
          console.log(x.response)
          snackctx.addAlert(x.data.message)
        } catch (error) {
          alert("error")
          console.log(error)
        }
      })
      .catch((error) => {
        try {
          snackctx.addAlert(ErrorsHelper.getValidationErrors(error), "error")
        } catch (error) {}
      })
  }

  const handleChange = (e) => {
    setState({ ...state, websiteType: e.target.value })
  }

  const handleTimeChange = (e) => {
    const { value, name } = e.target
    setState({ ...state, [name]: value })
  }

  const handleMessageChange = (e) => {
    setState({ ...state, message: e.target.value })
  }

  const handlePhoneChange = (e) => {
    setState({ ...state, phone: e.target.value })
  }

  const websiteChoice = (
    <>
      <p className="text-2xl capitalize text-center">New Website Form</p>
      <p className="text-gray-800 font-medium text-center">
        What website do you need?
      </p>

      <div
        id="website-type"
        className="flex items-center justify-center flex-wrap p-3 mb-2"
      >
        <div className="flex flex-col items-center justify-center gap-y-3 bg-gray-200 m-2 p-2 rounded-md">
          <TierMiniCard
            name="Static Website"
            description="A website that exists"
            src={staticWebsiteImg}
          />
          <input
            type="radio"
            name="website-type"
            value="static"
            onChange={handleChange}
          />
          {/* <label for="static" className="m-2">
        Static Website
      </label> */}
        </div>

        <div className="flex flex-col items-center justify-center gap-y-3 bg-gray-200 m-2 p-2 rounded-md">
          <TierMiniCard
            name="E-Commerce"
            description="A website to sell products"
            src={eCommerceImg}
          />
          <input
            type="radio"
            name="website-type"
            onChange={handleChange}
            value="e-commerce"
          />
          {/* <label for="e-commerce" className="m-2">
        e-commerce Website $600
      </label> */}
        </div>
        <div className="flex flex-col items-center justify-center gap-y-3 bg-gray-200 m-2 p-2 rounded-md">
          <TierMiniCard
            name="Custom"
            description="Neither of those. Something custom"
            src={CustomTools}
          />
          <input
            type="radio"
            name="website-type"
            onChange={handleChange}
            value="custom"
          />
          {/* <label for="custom" className="m-2">
        Custom Work
      </label> */}
        </div>
      </div>
    </>
  )

  const phoneinfo = (
    <>
      <div className="w-full">
        <p className="text-gray-800 font-medium text-center">
          When can we call you?
        </p>
        <p className="text-gray-800 font-sm text-center m-3 p-2">
          This does not garentee we will call at the specified time{" "}
        </p>

        <section className="flex flex-col m-auto gap-3  w-96">
          <label for="name" className="block text-sm text-gray-00">
            {" "}
            Date
          </label>
          <input
            className="w-full px-2 py-2 text-gray-700 bg-gray-200 rounded"
            name="day"
            type="date"
            onChange={handleTimeChange}
          />

          <label for="name" className="block text-sm text-gray-00">
            {" "}
            Time
          </label>
          <input
            className="w-full px-2 py-2 text-gray-700 bg-gray-200 rounded"
            name="time"
            type="time"
            onChange={handleTimeChange}
          />
          <label>Contact Phone</label>
          <input
            type="tel"
            className="w-full px-2 py-2 text-gray-700 bg-gray-200 rounded"
            placeholder={1231231234}
            required
            name="phone"
            onChange={handlePhoneChange}
            // pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
          ></input>
        </section>
      </div>
    </>
  )

  const message = (
    <>
      <div className="flex flex-col flex-wrap items-center gap-4 m-10 ">
        <p className="text-gray-800 font-medium text-center">
          {" "}
          Tell us about your future website
        </p>

        <textarea
          name="message"
          className="bg-gray-100 sm:w-96 w-full h-40"
          placeholder="Your message..."
          onChange={handleMessageChange}
        />
      </div>

      <div className="pt-3 flex flex-col items-center justify-center ">
        <button
          type="submit"
          className="px-4 py-1 text-white font-light tracking-wider bg-green-500 rounded"
        >
          Submit
        </button>
        <p className="text-gray-400 p-3 text-xs">
          Your card will not be charged here.
        </p>
      </div>
    </>
  )

  const main = (
    <div className="leading-loose">
      <form>
        <p className="text-gray-800 font-medium">New Website Form</p>
        {websiteChoice}
        <div className="">
          <label className="block text-sm text-gray-00" for="cus_name">
            Name
          </label>
          <input
            className="w-full px-5 py-1 text-gray-700 bg-gray-200 rounded"
            id="cus_name"
            name="cus_name"
            type="text"
            required=""
            placeholder="Your Name"
            aria-label="Name"
          />
        </div>
        <div className="mt-2">
          <label className="block text-sm text-gray-600" for="cus_email">
            Email
          </label>
          <input
            className="w-full px-5  py-4 text-gray-700 bg-gray-200 rounded"
            id="cus_email"
            name="cus_email"
            type="text"
            required=""
            placeholder="Your Email"
            aria-label="Email"
          />
        </div>
        <div className="mt-2">
          <label className=" block text-sm text-gray-600" for="cus_email">
            Address
          </label>
          <input
            className="w-full px-2 py-2 text-gray-700 bg-gray-200 rounded"
            id="cus_email"
            name="cus_email"
            type="text"
            required=""
            placeholder="Street"
            aria-label="Email"
          />
        </div>
        <div className="mt-2">
          <label className="hidden text-sm block text-gray-600" for="cus_email">
            City
          </label>
          <input
            className="w-full px-2 py-2 text-gray-700 bg-gray-200 rounded"
            id="cus_email"
            name="cus_email"
            type="text"
            required=""
            placeholder="City"
            aria-label="Email"
          />
        </div>
        <div className="inline-block mt-2 w-1/2 pr-1">
          <label className="hidden block text-sm text-gray-600" for="cus_email">
            Country
          </label>
          <input
            className="w-full px-2 py-2 text-gray-700 bg-gray-200 rounded"
            id="cus_email"
            name="cus_email"
            type="text"
            required=""
            placeholder="Country"
            aria-label="Email"
          />
        </div>
        <div className="inline-block mt-2 -mx-1 pl-1 w-1/2">
          <label className="hidden block text-sm text-gray-600" for="cus_email">
            Zip
          </label>
          <input
            className="w-full px-2 py-2 text-gray-700 bg-gray-200 rounded"
            id="cus_email"
            name="cus_email"
            type="text"
            required=""
            placeholder="Zip"
            aria-label="Email"
          />
        </div>
        <p className="mt-4 text-gray-800 font-medium">Payment information</p>
        <div className="">
          <label className="block text-sm text-gray-600" for="cus_name">
            Card
          </label>
          <input
            className="w-full px-2 py-2 text-gray-700 bg-gray-200 rounded"
            id="cus_name"
            name="cus_name"
            type="text"
            required=""
            placeholder="Card Number MM/YY CVC"
            aria-label="Name"
          />
        </div>
        <div className="mt-4">
          <button
            className="px-4 py-1 text-white font-light tracking-wider bg-gray-900 rounded"
            type="submit"
          >
            $3.00
          </button>
        </div>
      </form>
    </div>
  )

  return (
    <div className="bg-white shadow-lg">
      <form onSubmit={handleSubmit} className="p-4 flex flex-col flex-wrap">
        {websiteChoice}
        {phoneinfo}
        {message}
      </form>
    </div>
  )
}

export default Order
