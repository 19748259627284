import React,{useContext} from "react"
import Loader from "react-loader-spinner"
import { Link, useParams } from "react-router-dom"
import { passwordReset } from "../../API/Auth/email"
import logo from "../../assets/svg/Logo.svg"
import { SnackbarContext } from "../../contexts/SnackbarState"
const PasswordReset = () => {
  const [state, setState] = React.useState({
    newPass: "",
    loading: false,
  })
    const { jwt } = useParams()
    const snackCtx = useContext(SnackbarContext)

  const handleChange = (e) => {
    const { value, name } = e.target
    setState({ ...state, [name]: value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    setState({ ...state, loading: true })
    try {
      const response = await passwordReset( state.newPass,jwt)
      if (response?.data?.message) {
        snackCtx.addAlert(response.data.message,"success")
      }
      setState({ ...state, loading: false })
    } catch (error) {
      if (error?.response?.data?.error) {
        snackCtx.addAlert(error.response.data.error,"error")
      }
      setState({ ...state, loading: false })
    }
  }
  return (
    <>
      <section className="h-full bg-hero bg-no-repeat bg-cover ">
        <div className=""></div>
        <div className="container mx-auto px-4 h-screen">
          <div className="flex content-center items-center justify-center h-full">
            <div className="w-full lg:w-4/12 px-4 pt-32">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300 border-0 bg-opacity-50 backdrop-filter backdrop-blur-md">
                <div className="rounded-t mb-0 px-6 py-6">
                  <div className="text-center mb-3">
                    <div className="flex items-center justify-center mb-2">
                      <img src={logo} width={100} alt="logo" />
                    </div>
                    <h6 className="text-gray-600 text-2xl font-bold">
                      Password Reset
                    </h6>
                  </div>
                  <div className="btn-wrapper text-center">
                    {/* <button
                    className="bg-white active:bg-gray-100 text-gray-800 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-2 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs"
                    type="button"
                    style={{ transition: "all 0.15s ease 0s" }}
                  >
                    <img
                      alt="..."
                      className="w-5 mr-1"
                      src="https://demos.creative-tim.com/tailwindcss-starter-project/static/media/github.4ffd4fe7.svg"
                    />
                    Github
                  </button>
                  <button
                    className="bg-white active:bg-gray-100 text-gray-800 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs"
                    type="button"
                    style={{ transition: "all 0.15s ease 0s" }}
                  >
                    <img
                      alt="..."
                      className="w-5 mr-1"
                      src="https://demos.creative-tim.com/tailwindcss-starter-project/static/media/google.87be59a1.svg"
                    />
                    Google
                  </button> */}
                  </div>
                  <hr className="mt-6 border-b-1 border-gray-400" />
                </div>
                <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                  {/* <div className="text-gray-500 text-center mb-3 font-bold">
                  <small>Sign in with Email</small>
                </div> */}
                  <form onSubmit={handleSubmit}>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                        for="grid-password"
                      >
                        New Password
                      </label>
                      <input
                        type="password"
                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                        placeholder="new password"
                        style={{ transition: "all 0.15s ease 0s" }}
                        name="newPass"
                        onChange={handleChange}
                      />
                    </div>

                    <div>
                      {/* <label className="inline-flex items-center cursor-pointer">
                      <input
                        id="customCheckLogin"
                        type="checkbox"
                        className="form-checkbox text-gray-800 ml-1 w-5 h-5"
                        style={{ transition: "all 0.15s ease 0s" }}
                      />
                      <span className="ml-2 text-sm font-semibold text-gray-700">
                        Remember me
                      </span>
                    </label> */}
                    </div>
                    <div className="text-center mt-6">
                      <button
                        className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full"
                        style={{ transition: "all 0.15s ease 0s" }}
                        type="submit"
                      >
                        {state.loading === false ? (
                          "Reset"
                        ) : (
                          <div className="flex items-center justify-center">
                            <Loader
                              type="TailSpin"
                              color="#ffffff"
                              height={20}
                              width={20}
                            />
                          </div>
                        )}
                      </button>
                    </div>
                    <div className="flex flex-wrap mt-6 text-white capitalize">
                      <div className="w-1/3">
                        <Link to="/signin">
                          <small>Sign in</small>
                        </Link>
                      </div>
                      <div className="w-1/3 text-center capitalize">
                        <a href="/forgot-password ">
                          <small>Forgot Password</small>
                        </a>
                      </div>
                      <div className="w-1/3 text-right capitalize">
                        <Link to="/signup">
                          <small>Create new account</small>
                        </Link>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default PasswordReset
