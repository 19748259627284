import React from "react"

import CookiesPopup from "../../components/CookiesPopup"
import Features from "../../components/Features"
import Gallery from "../../components/Gallery"
import Hero from "../../components/Hero"
import Pricing from "../../components/Pricing"
import vars from "../../vars"
import ipAPI from "../../API/Ips"

export default function Home() {

  // const dataHasLoaded = React.useRef(false)

  // React.useEffect(() => {
  //   const init = async() => {
  //     await ipAPI.saveIps()
  //   }
  //   if (dataHasLoaded.current === false) {
  //     init()
  //      dataHasLoaded.current = true
  //   }
  
  // }, [])
  // const testimonial = (
  //   <section className="text-gray-600 body-font">
  //     <h1 className="sm:text-4xl text-3xl font-medium title-font mb-2 text-gray-900 text-center">
  //       Reviews
  //     </h1>
  //     <div className="container px-5 py-24 mx-auto">
  //       <div className="flex flex-wrap -m-4">
  //         <div className="lg:w-1/3 lg:mb-0 mb-6 p-4">
  //           <div className="h-full text-center">
  //             <img
  //               alt="testimonial"
  //               className="w-20 h-20 mb-8 object-cover object-center rounded-full inline-block border-2 border-gray-200 bg-gray-100"
  //               src="https://dummyimage.com/302x302"
  //             />
  //             <p className="leading-relaxed">
  //               Edison bulb retro cloud bread echo park, helvetica stumptown
  //               taiyaki taxidermy 90's cronut +1 kinfolk. Single-origin coffee
  //               ennui shaman taiyaki vape DIY tote bag drinking vinegar cronut
  //               adaptogen squid fanny pack vaporware.
  //             </p>
  //             <span className="inline-block h-1 w-10 rounded bg-indigo-500 mt-6 mb-4"></span>
  //             <h2 className="text-gray-900 font-medium title-font tracking-wider text-sm">
  //               HOLDEN CAULFIELD
  //             </h2>
  //             <p className="text-gray-500">Senior Product Designer</p>
  //           </div>
  //         </div>
  //         <div className="lg:w-1/3 lg:mb-0 mb-6 p-4">
  //           <div className="h-full text-center">
  //             <img
  //               alt="testimonial"
  //               className="w-20 h-20 mb-8 object-cover object-center rounded-full inline-block border-2 border-gray-200 bg-gray-100"
  //               src="https://dummyimage.com/300x300"
  //             />
  //             <p className="leading-relaxed">
  //               Edison bulb retro cloud bread echo park, helvetica stumptown
  //               taiyaki taxidermy 90's cronut +1 kinfolk. Single-origin coffee
  //               ennui shaman taiyaki vape DIY tote bag drinking vinegar cronut
  //               adaptogen squid fanny pack vaporware.
  //             </p>
  //             <span className="inline-block h-1 w-10 rounded bg-indigo-500 mt-6 mb-4"></span>
  //             <h2 className="text-gray-900 font-medium title-font tracking-wider text-sm">
  //               ALPER KAMU
  //             </h2>
  //             <p className="text-gray-500">UI Develeoper</p>
  //           </div>
  //         </div>
  //         <div className="lg:w-1/3 lg:mb-0 p-4">
  //           <div className="h-full text-center">
  //             <img
  //               alt="testimonial"
  //               className="w-20 h-20 mb-8 object-cover object-center rounded-full inline-block border-2 border-gray-200 bg-gray-100"
  //               src="https://dummyimage.com/305x305"
  //             />
  //             <p className="leading-relaxed">
  //               Edison bulb retro cloud bread echo park, helvetica stumptown
  //               taiyaki taxidermy 90's cronut +1 kinfolk. Single-origin coffee
  //               ennui shaman taiyaki vape DIY tote bag drinking vinegar cronut
  //               adaptogen squid fanny pack vaporware.
  //             </p>
  //             <span className="inline-block h-1 w-10 rounded bg-indigo-500 mt-6 mb-4"></span>
  //             <h2 className="text-gray-900 font-medium title-font tracking-wider text-sm">
  //               HENRY LETHAM
  //             </h2>
  //             <p className="text-gray-500">CTO</p>
  //           </div>
  //         </div>
  //       </div>
  //     </div>
  //   </section>
  // )

  const contact = (
    <section className="text-gray-600 body-font relative ">
      <div className="  w-full h-96 p-20 bg-gray-100">
        <div className="flex flex-col text-center">
          <h1 className="sm:text-3xl text-2xl font-medium title-font mb-4 text-gray-900">
            Contact Us
          </h1>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-base">
            So we can work with you today
          </p>
        </div>
        <div className="lg:w-1/2 md:w-2/3 mx-auto ">
          <div className="flex flex-wrap -m-2">
            {/* <div className="p-2 w-1/2">
          <div className="relative">
            <label for="name" className="leading-7 text-sm text-gray-600">Name</label>
            <input type="text" id="name" name="name" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-green-500 focus:bg-white focus:ring-2 focus:ring-green-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
          </div>
        </div> */}
            {/* <div className="p-2 w-1/2">
          <div className="relative">
            <label for="email" className="leading-7 text-sm text-gray-600">Email</label>
            <input type="email" id="email" name="email" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-green-500 focus:bg-white focus:ring-2 focus:ring-green-200 text-base outline-none text-gray-700 py-1 px-3 leading-8 transition-colors duration-200 ease-in-out"/>
          </div>
        </div> */}
            {/* <div className="p-2 w-full">
          <div className="relative">
            <label for="message" className="leading-7 text-sm text-gray-600">Message</label>
            <textarea id="message" name="message" className="w-full bg-gray-100 bg-opacity-50 rounded border border-gray-300 focus:border-green-500 focus:bg-white focus:ring-2 focus:ring-green-200 h-32 text-base outline-none text-gray-700 py-1 px-3 resize-none leading-6 transition-colors duration-200 ease-in-out"></textarea>
          </div>
        </div> */}
            {/* <div className="p-2 w-full">
          <button className="flex mx-auto text-white bg-green-500 border-0 py-2 px-8 focus:outline-none hover:bg-green-600 rounded text-lg">Button</button>
        </div> */}
            <div className="p-2 w-full text-center ">
              <a
                className="text-green-500"
                href={`mailto:${vars.company.email}`}
              >
                {vars.company.email}
              </a>
              <p className="leading-normal my-5">
                {vars.company.street}
                <br />
                {vars.company.city}, {vars.company.state} {vars.company.zip}
              </p>
              <span className="inline-flex">
                {/* facebook */}
                <a className="text-gray-500 hover:text-blue-500" href="/">
                  <svg
                    fill="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="w-5 h-5"
                    viewBox="0 0 24 24"
                  >
                    <path d="M18 2h-3a5 5 0 00-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 011-1h3z"></path>
                  </svg>
                </a>
                <a className="ml-4  hover:text-blue-300" href="/">
                  <svg
                    fill="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="w-5 h-5"
                    viewBox="0 0 24 24"
                  >
                    <path d="M23 3a10.9 10.9 0 01-3.14 1.53 4.48 4.48 0 00-7.86 3v1A10.66 10.66 0 013 4s-4 9 5 13a11.64 11.64 0 01-7 2c9 5 20 0 20-11.5a4.5 4.5 0 00-.08-.83A7.72 7.72 0 0023 3z"></path>
                  </svg>
                </a>
                <a className="ml-4 text-gray-500 " href="/">
                  <svg
                    fill="none"
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="w-5 h-5"
                    viewBox="0 0 24 24"
                  >
                    <rect
                      width="20"
                      height="20"
                      x="2"
                      y="2"
                      rx="5"
                      ry="5"
                    ></rect>
                    <path d="M16 11.37A4 4 0 1112.63 8 4 4 0 0116 11.37zm1.5-4.87h.01"></path>
                  </svg>
                </a>
                <a className="ml-4 text-gray-500" href="/">
                  <svg
                    fill="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    className="w-5 h-5"
                    viewBox="0 0 24 24"
                  >
                    <path d="M21 11.5a8.38 8.38 0 01-.9 3.8 8.5 8.5 0 01-7.6 4.7 8.38 8.38 0 01-3.8-.9L3 21l1.9-5.7a8.38 8.38 0 01-.9-3.8 8.5 8.5 0 014.7-7.6 8.38 8.38 0 013.8-.9h.5a8.48 8.48 0 018 8v.5z"></path>
                  </svg>
                </a>
              </span>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
  return (
    <div>
      <div className="flex justify-center">
        <CookiesPopup />
      </div>
      <Hero />
      <Features />
      <Pricing />
      <Gallery />
      {/* {testimonial} */}

      {contact}
    </div>
  )
}
