import React from "react"

const ErrorButton = ({ onClick,children,className }) => {

  const handleClick = (e) => {
    onClick()
  }
    
  return (
    <button className={`${"bg-red-500 ring-red-500 p-5 text-white   w-64 p-2"} ${className}`} onClick={handleClick}>
     {children}
    </button>
  )
}

export default ErrorButton
