import { Elements } from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import React from "react"
import { BrowserRouter as Router, Route, Switch } from "react-router-dom"
import PrivateRoute from "./components/auth/PrivateRoute"
import ForgotPassword from "./components/ForgotPassword"
import GlobalState from "./contexts/GlobalState"
import SnackbarProvider from "./contexts/SnackbarState"
import UserDashboardState from "./contexts/UserDashboardState"
import DashboardTemplate from "./Pages/Dashboard"
import Error_404 from "./Pages/Error_404"
import PricingPage from "./Pages/Pricing"
import MainTemplate from "./Pages/Template"
import routes from "./routes"

const stripe = loadStripe(
  "pk_test_51GsZbwCMDV1uumPrWFfUdP4LYnGby5IfjycvsEMRU23alveSQmYBeDv2uv8nP9mQmRTdarHfcyuZR7lvl8di09AU00njeer4OI"
)

function App() {
  return (
    <GlobalState>
      <Elements stripe={stripe}>
        <SnackbarProvider>
          <Router>
            <div>
              <Switch>
                {routes.publicRoutes.map((x, i) => {
                  return (
                    <Route key={`${x.name}${i}`} path={x.path} exact>
                      <MainTemplate>{x.component}</MainTemplate>
                    </Route>
                  )
                })}

                {routes.privateRoutes.map((x, i) => {
                  return (
                    <PrivateRoute key={`${x.name}${i}`} path={x.path} exact>
                      <MainTemplate>
                        <UserDashboardState>
                          <DashboardTemplate>{x.component}</DashboardTemplate>
                        </UserDashboardState>
                      </MainTemplate>
                    </PrivateRoute>
                  )
                })}
                {routes.authRoutes.map((x, i) => {
                  return (
                    <Route key={`${x.name}${i}`} path={x.path} exact>
                      <MainTemplate>{x.component}</MainTemplate>
                    </Route>
                  )
                })}

                {routes.authRoutesNoDash.map((x, i) => {
                  return (
                    <PrivateRoute key={`${x.name}${i}`} path={x.path} exact>
                      <MainTemplate>{x.component}</MainTemplate>
                    </PrivateRoute>
                  )
                })}
                {routes.authRoutesNoHeaderLink.map((x, i) => {
                  return (
                    <Route key={`${x.name}${i}`} path={x.path} exact>
                      <MainTemplate>{x.component}</MainTemplate>
                    </Route>
                  )
                })}

                {/* <Route path="/forgot-password">
                  <MainTemplate>
                    <ForgotPassword/>
                  </MainTemplate>
                </Route>
                <Route path="/password-teset/:jwt">
                  <MainTemplate>
                    <ForgotPassword/>
                  </MainTemplate>
                </Route> */}

                <Route path="/more-info">
                  <MainTemplate>
                    <PricingPage />
                  </MainTemplate>
                </Route>
                {/* <Route to="/testing" component={TestingPage} /> */}
                <Route component={Error_404} />
              </Switch>
            </div>
          </Router>
        </SnackbarProvider>
      </Elements>
    </GlobalState>
  )
}

export default App
