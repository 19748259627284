import React from "react"
import { ArrowLeft } from "react-feather"
import { useHistory } from "react-router-dom"
import errorImage from "../../assets/img/404.png"

const Error_404 = () => {
  const hist = useHistory()

  const goback = () => {
    hist.goBack()
  }
  return (
    <div className="w-screen h-screen flex  items-center justify-center flex-col">
      <img
        src={errorImage}
        style={{ height: 300 }}
        alt="404 error. Page not found"
      />
      <h2 className="text-lg">Sorry mate. Nothing here.</h2>
      <button
        className="p-2 rounded border border-green-500 flex text-green-500 mt-6"
        onClick={goback}
      >
        <ArrowLeft /> Go Back
      </button>
    </div>
  )
}

export default Error_404
