import React from "react"
import { Trash } from "react-feather"

const CreditCardItem = ({
  brand,
  last4,
  exp_month,
  exp_year,
  name,
  id,
  handleDeleteCard,
}) => {
  const handleDelete = async (e) => {
    alert(`deleting card :${id}`)
    handleDeleteCard(id)
  }

  return (
    <div className="bg-gradient-to-r from-blue-300  to-blue-500 w-72 h-40 m-2 p-2 rounded-xl border border-blue-200 shadow">
      <div className="flex flex-row justify-between">
        <button className="text-red-400 " onClick={handleDelete}>
          <Trash />
        </button>
        <p className="p-3 text-white"> {brand}</p>
      </div>
      <div className="flex flex-row justify-center gap-x-5 p-5 text-white">
        <p>xxxx</p>
        <p>xxxx</p>
        <p>xxxx</p>
        <p>{last4}</p>
      </div>
      <div className="flex flex-row justify-between text-white pl-8 pr-8">
        <div>{name}</div>
        <div className="flex flex-row">
          <p>{exp_month}/</p>
          <p>{exp_year}</p>
        </div>
      </div>
    </div>
  )
}

export default CreditCardItem
