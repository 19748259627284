import React from "react"

const CookiesPopup = () => {
  const [state, setstate] = React.useState({
    clicked: true, //assume user has agreed
  })

  React.useEffect(() => {
    try {
      const cookie = JSON.parse(localStorage.getItem("agreeToCookie")) //if this does not exist show the  popup
      if (!cookie) {
        setstate({ ...state, clicked: false })
      }
    } catch (error) {
      if (error) console.log(error)
    }
  }, [])

  const handleClick = () => {
    setstate({ ...state, clicked: !state.clicked })
    localStorage.setItem("agreeToCookie", "true")
  }
  return (
    <>
      {state.clicked === false ? (
        <div
          style={{ position: "fixed", zIndex: 50, marginTop: "80vh" }}
          className="max-w-2xl px-8 py-4  bg-white rounded-lg shadow-md dark:bg-gray-800"
        >
          <div className="mt-2">
            <p className="text-2xl font-bold text-gray-700 dark:text-white hover:text-gray-600 dark:hover:text-gray-200 underline">
              Cookies Notification
            </p>
            <p className="mt-2 text-gray-600 dark:text-gray-300">
              Hello! <b> This website is still under construction.</b> Please
              come back at a later time. This site uses cookies. We use them
              mainly for security and as such by using this website you agree to
              use these cookies.
            </p>
          </div>

          <div className="flex items-center justify-between mt-4">
            <div className="text-blue-600 dark:text-blue-400 hover:underline"></div>

            <div className="flex items-center">
              <button
                className="border-none rounded-xl p-2 bg-green-400 text-white w-20"
                onClick={handleClick}
              >
                Ok
              </button>
            </div>
          </div>
        </div>
      ) : null}
    </>
  )
}

export default CookiesPopup
