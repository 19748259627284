import axios      from "axios"

import { isAuth } from "../Auth/email"

const base = `${process.env.REACT_APP_API}/stripe/balance`

/**
 * @description returns balance
 * @returns {Number}
 */
export const get = async () => {
  const url = `${base}/get`
  const jwt = isAuth()
  if (!jwt) {
    return "error"
  }

  return await axios.post(
    url,
    {},
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      withCredentials: true,
    }
  )
}

const main = {
  get,
}
export default main
