import Cookies from "js-cookie"
import React from "react"
import { Link, useHistory } from "react-router-dom"
import logo from "../../assets/svg/Logo.svg"
import { GlobalContext } from "../../contexts/GlobalState"
import { SnackbarContext } from "../../contexts/SnackbarState"
const SignIn = () => {
  const [state, setstate] = React.useState({
    email: "",
    password: "",
  })

  const globalState = React.useContext(GlobalContext)
  const snackbarctx = React.useContext(SnackbarContext)

  const hist = useHistory()

  const handleChange = (e) => {
    e.preventDefault()
    const { value, name } = e.target
    setstate({ ...state, [name]: value })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (state.email !== "" || state.password !== "") {
      await globalState.auth
        .login(state.email, state.password)
        .then((response) => {
          if (Cookies.get("publicStuff") !== undefined) {
            alert("moving to dashboard")
            hist.push("/dashboard")
          }
        })
        .catch((error) => {
          try {
            snackbarctx.addAlert(error.response.data.error, "error")
          } catch (error) {}
        })
    } else {
      alert("something is empty")
    }
  }
  return (
    <>
      <section className="h-full bg-hero bg-no-repeat bg-cover ">
        <div className=""></div>
        <div className="container mx-auto px-4 h-screen">
          <div className="flex content-center items-center justify-center h-full">
            <div className="w-full lg:w-4/12 px-4 pt-32">
              <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300 border-0 bg-opacity-50 backdrop-filter backdrop-blur-md">
                <div className="rounded-t mb-0 px-6 py-6">
                  <div className="text-center mb-3">
                    <div className="flex items-center justify-center mb-2">
                      <img src={logo} width={100} alt="logo" />
                    </div>
                    <h6 className="text-gray-600 text-2xl font-bold">
                      Sign In
                    </h6>
                  </div>
                  <div className="btn-wrapper text-center">
                    {/* <button
                      className="bg-white active:bg-gray-100 text-gray-800 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-2 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs"
                      type="button"
                      style={{ transition: "all 0.15s ease 0s" }}
                    >
                      <img
                        alt="..."
                        className="w-5 mr-1"
                        src="https://demos.creative-tim.com/tailwindcss-starter-project/static/media/github.4ffd4fe7.svg"
                      />
                      Github
                    </button>
                    <button
                      className="bg-white active:bg-gray-100 text-gray-800 font-normal px-4 py-2 rounded outline-none focus:outline-none mr-1 mb-1 uppercase shadow hover:shadow-md inline-flex items-center font-bold text-xs"
                      type="button"
                      style={{ transition: "all 0.15s ease 0s" }}
                    >
                      <img
                        alt="..."
                        className="w-5 mr-1"
                        src="https://demos.creative-tim.com/tailwindcss-starter-project/static/media/google.87be59a1.svg"
                      />
                      Google
                    </button> */}
                  </div>
                  <hr className="mt-6 border-b-1 border-gray-400" />
                </div>
                <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                  {/* <div className="text-gray-500 text-center mb-3 font-bold">
                    <small>Sign in with Email</small>
                  </div> */}
                  <form onSubmit={handleSubmit}>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                        for="grid-password"
                      >
                        Email
                      </label>
                      <input
                        id="sign-in-email"
                        type="email"
                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                        placeholder="Email"
                        style={{ transition: "all 0.15s ease 0s" }}
                        name="email"
                        onChange={handleChange}
                      />
                    </div>
                    <div className="relative w-full mb-3">
                      <label
                        className="block uppercase text-gray-700 text-xs font-bold mb-2"
                        for="grid-password"
                      >
                        Password
                      </label>
                      <input
                        id="sign-in-password"
                        type="password"
                        className="px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:shadow-outline w-full"
                        placeholder="Password"
                        style={{ transition: "all 0.15s ease 0s" }}
                        name="password"
                        onChange={handleChange}
                      />
                    </div>
                    <div>
                      {/* <label className="inline-flex items-center cursor-pointer">
                        <input
                          id="customCheckLogin"
                          type="checkbox"
                          className="form-checkbox text-gray-800 ml-1 w-5 h-5"
                          style={{ transition: "all 0.15s ease 0s" }}
                        />
                        <span className="ml-2 text-sm font-semibold text-gray-700">
                          Remember me
                        </span>
                      </label> */}
                    </div>
                    <div className="text-center mt-6">
                      <button
                        className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full"
                        style={{ transition: "all 0.15s ease 0s" }}
                        type="submit"
                      >
                        Sign In
                      </button>
                    </div>
                    <div className="flex flex-wrap mt-6 text-white">
                      <div className="w-1/2">
                        <Link to="/forgot-password">
                          <small>Forgot password?</small>
                        </Link>
                      </div>
                      <div className="w-1/2 text-right">
                        <Link to="/signup">
                          <small>Create new account</small>
                        </Link>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default SignIn
