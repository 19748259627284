import React from "react"
import ProfileCard from "../../components/Profile"

const Profile = () => {
  return (
    <div className="flex flex-col m-auto">
      <ProfileCard />
    </div>
  )
}

export default Profile
