import Cookies from "js-cookie"

const getPublicJwt = () => {
	const cookie = Cookies.get("publicStuff")
	if (!cookie) {
		return false
	}
	return cookie
}

export default getPublicJwt
