import React from "react"
import vars from "../../vars.js"
import logo from "../../assets/svg/dark-logo.svg"
const Hero = () => {
  return (
    <section className="text-gray-600 body-font bg-white bg-hero bg-no-repeat bg-cover h-screen ">
      <div className="bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm h-screen">
        <div className="text-center m-auto items-center  w-1/2 pt-60">
          <div className="flex items-center justify-center ">
            <img src={logo} width={300} height={300} alt="alpha beta logo"/>
          </div>
          <h1 className="title-font sm:text-9xl text-7xl mb-4 font-medium text-dark text-white justify-center tahu-font mt-4">
            {vars.company.name}
          </h1>
          <h4 className="text-white  text-xl font-medium">
            {vars.company.slogan}
          </h4>
        </div>
      </div>
    </section>
  )
}

export default Hero
