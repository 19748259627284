import React, { useContext } from "react"
import { GlobalContext } from "../../contexts/GlobalState"
import ErrorButton from "../ErrorButton"

const DeleteAccount = ({ children, className }) => {
  const user = useContext(GlobalContext)
  const handleClick = async (e) => {
    alert("deleting Account")
      await user.auth.deleteAccount().then(x => {
          console.log(x)
          console.log("deleting account")
          alert("Deleted account")
      }).catch(error => {
          console.log("could not delete account")
          console.log(error)
    })
  }

  return (
    <ErrorButton onClick={handleClick} className={className}>
      {children}
    </ErrorButton>
  )
}

export default DeleteAccount
