import React from "react"
import DeleteAccount from "../../../../components/DeleteAccountButton"

const Settings = () => {
  return (
    <div className="m-auto bg-green-300">
      <div className="m-auto bg-green-200">
        <div className="flex items-center justify-center w-full mx-auto bg-green-100">{<DeleteAccount>Delete Account</DeleteAccount>}</div>
      </div>
    </div>
  )
}

export default Settings
