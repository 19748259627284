import React from "react"
import { HashLink } from 'react-router-hash-link'
import vars from "../../vars"

const Pricing = () => {
  const item = (tier, i) => (
    <div className="p-4 xl:w-1/3 md:w-1/2 w-full bg-white border backdrop-filter backdrop-blur-md bg-opacity-10">
      <div className="h-full p-6 rounded-lg border-2 border-gray-300 flex flex-col relative overflow-hidden">
        {i === 1 ? (
          <span className="bg-green-500 text-white px-3 py-1 tracking-widest text-xs absolute right-0 top-0 rounded-bl">
            POPULAR
          </span>
        ) : null}
        <h2 className="text-sm tracking-widest title-font mb-1 font-medium text-white">
          {tier.name.toUpperCase()}
        </h2>
        <h1 className="text-5xl text-white pb-4 mb-4 border-b border-gray-200 leading-none">
          {tier.price}
          {/* <span className="text-lg ml-1 font-normal text-gray-500">/mo</span> */}
        </h1>
        {tier.features.map((x,i) => {
          return (
            <p key={`tier-${i}`} className="flex items-center text-white mb-2">
              <span className="w-4 h-4 mr-2 inline-flex items-center justify-center bg-gray-400 text-white rounded-full flex-shrink-0">
                <svg
                  fill="none"
                  stroke="currentColor"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2.5"
                  className="w-3 h-3"
                  viewBox="0 0 24 24"
                >
                  <path d="M20 6L9 17l-5-5"></path>
                </svg>
              </span>
              {x}
            </p>
          )
        })}

        <HashLink to={`/more-info/#${tier.name}`} className="mt-auto ">
          <button className="flex items-center mt-auto text-white bg-gray-400 border-0 py-2 px-4 w-full focus:outline-none hover:bg-gray-500 rounded">
           More Info
            <svg
              fill="none"
              stroke="currentColor"
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth="2"
              className="w-4 h-4 ml-auto"
              viewBox="0 0 24 24"
            >
              <path d="M5 12h14M12 5l7 7-7 7"></path>
            </svg>
          </button>
        </HashLink>

        <p className="text-xs text-white mt-3">
          To find out more, click the button above
        </p>
      </div>
    </div>
  )

  return (
    <section className="text-gray-600 body-font overflow-hidden bg-ice bg-cover bg-no-repeat">
      <div className="container px-5 py-24 mx-auto">
        <div className="flex flex-col text-center w-full mb-20">
          <h1 className="sm:text-4xl text-3xl font-medium title-font mb-2 text-white">
            {vars.pricing.title}
          </h1>
          <p className="lg:w-2/3 mx-auto leading-relaxed text-base text-white text-2xl">
            {vars.pricing.subtitle}
          </p>
          <div className="flex mx-auto border-2 border-green-500 rounded overflow-hidden mt-6">
            <button className="py-1 px-4 bg-green-500 text-white focus:outline-none">
             1 time payment
            </button>
            {/* <button className="py-1 px-4 focus:outline-none text-white">Monthly</button> */}
          </div>
        </div>
        <div className="flex flex-wrap -m-4">
          {vars.pricing.tiers.map((tier, i) => {
            return <React.Fragment key={`tier-pricing-${i}`} >{item(tier, i)}</React.Fragment>
          })}

        
        </div>
      </div>
    </section>
  )
}

export default Pricing
