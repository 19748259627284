import axios from "axios"
import axiosSource from "../../helpers/axiosHelper"
import { isAuth } from "../Auth/email"

const base = `${process.env.REACT_APP_API}/stripe/products`

const create = async (formData) => {
  const jwt = isAuth()
  return await axios.post(`${base}/create`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
      Authorization: `Bearer ${jwt}`,
    },
    withCredentials: true,
  })
}

const get = async () => {
  const jwt = isAuth()
  return await axios.get(
    `${base}/get`,
    {},
    {
      
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      withCredentials: true,
      cancleToken:axiosSource.token
    },
    
  )
}

const getAll = async () => {
  const jwt = isAuth()
  return await axios.post(
    `${base}/get-all`,
    {},
    {
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${jwt}`,
      },
      withCredentials: true,
      
    }
  )
}

const deactivate = async (id) => {
  const jwt = isAuth()
  return await axios.post(
    `${base}/deactivate/${id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      withCredentials: true,
    }
  )
}

const activate = async (id) => {
  const jwt = isAuth()
  return await axios.post(
    `${base}/activate/${id}`,
    {},
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      withCredentials: true,
    }
  )
}

/**
 * @description gets the stripe products
 * @param {Array} productIds
 */
const getCart = async () => {
  const cartIds = JSON.parse(localStorage.getItem("cart"))
  return await axios.post(`${base}/get-cart`, { cartIds })
}

/**
 * @description gets all the product ids from local storage and returns them as a list
 * @returns {Array} stripe cart Ids
 */
const getCartIds = () => {
  return JSON.parse(localStorage.getItem("cart"))
}

/**
 *
 */
const createEmptyCart = () => {
  if (!getCartIds()) {
    localStorage.setItem("cart", JSON.stringify([]))
  }
}

/**
 *
 * @param {Array} arr takes in an array of cart ids{string} and saves them to local storage
 * @returns {Boolean} success
 */
const saveCartStorage = (arr) => {
  if (Array.isArray(arr)) {
    localStorage.setItem("cart", JSON.stringify(arr))
    return true
  }
  return false
}

/**Removes product ids from localStorage */
const removeFromCart = (id) => {
  const cart = getCartIds()
  if (Array.isArray(cart)) {
    const index = cart.indexOf(id)
    cart.splice(index, 1)
    if (saveCartStorage(cart) === true) {
      return true
    }
  }
  return false
}

/**
 * @description Adds a new product id{String} the localStorage "cart" array
 * @param {String} id
 * @returns {Boolean} success
 */
const addToCart = (id) => {
  if (!id) {
    // console.error("id is null in add to cart")
    return false
  }

  let listOfCartIDs = getCartIds()
  if (!listOfCartIDs) {
    let temp = []
    temp.push(id)
    saveCartStorage(temp)
    return true
  }
  // return saveCartStorage([...listOfCartIDs, id])
  return  saveCartStorage([...new Set([...listOfCartIDs, id])])
}

const getPhoto = (id)=>`${base}/photo/${id}`




const main = {
  create,
  get,
  activate,
  deactivate,
  getCart,
  getCartIds,
  addToCart,
  removeFromCart,
  createEmptyCart,
  getAll,
  getPhoto
}
export default main
