import axios from "axios"

import { isAuth } from "../Auth/email"

const base = `${process.env.REACT_APP_API}/stripe/customer`

// /**
//  * @description saves the user's card but only saves 1 card | cardNumber,name, expiry,cvc
//  * @param {Object} card
//  */
// export const saveCardToUser = async (card, address) => {
//   const { number, name, expiry, cvc } = card
//   const { city, country, line1, zip, state } = address
//   const url = `${base}/create-user-with-card`
//   const jwt = isAuth()
//   return await axios.post(
//     url,
//     {
//       card: {
//         number,
//         name,
//         expiry,
//         cvc,
//       },
//       address: {
//         city,
//         country,
//         line1,
//         zip,
//         state,
//       },
//     },
//     {
//       headers: {
//         Authorization: `Bearer ${jwt}`,
//       },
//       withCredentials: true,
//     }
//   )
// }

/**
 * @description saves the user's card but only saves 1 card | cardNumber,name, expiry,cvc
 * @param {Object} card
 */
export const createSetupIntent = async () => {
  const url = `${base}/setup-intent/create`
  const jwt = isAuth()
  return await axios.post(
    url,
    {},
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
      withCredentials: true,
    }
  )
}
