import axios from "axios"

const base = `${process.env.REACT_APP_API}/ips`

/**
 * @description save ip addresses
 */
const saveIps = async () => {
  const url = `${base}/saveip`

  return await axios.post(url, {}, {})
}

const main = {
  saveIps,
}
export default main
