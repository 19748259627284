import React from "react"
import { LogOut, User } from "react-feather"
import { useHistory } from "react-router-dom"
import { logout } from "../../API/Auth/email"
const Avatar = () => {
  const [state, setState] = React.useState({
    show: false,
  })
  const history = useHistory()

  const handleClick = () => {
    setState({ show: !state.show })
  }

  const handleSignout = async () => {
    await logout()
      .then((x) => {
        history.push("/")
      })
      .catch((error) => {
        console.log(error)
      })
  }

  const handleNavigate = (location) => {
    history.push(location)
  }
  return (
    <div
      id="avatar"
      className="rounded-full bg-gray-300 w-16 h-16 ml-auto flex items-center justify-center relative"
      onClick={handleClick}
    >
      <User className="m-auto" />
      <div
        className={`transition-all duration-500 ease-in-out rounded-xl bg-white absolute top-20 min-h-20 w-60 ${
          state.show === true ? "visible" : "hidden"
        } `}
      >
        <ul className="p-4 flex gap-x-5">
          <li>
            <button
              id="avatar-profile-button"
              className="flex flex-row gap-2 items-center bg-green-500 p-2 rounded-md text-white hover:bg-green-400 "
              onClick={()=>handleNavigate("/dashboard")}
            >
              <User /> Profile
            </button>
          </li>
          <li
            onClick={handleSignout}
            className="flex flex-row gap-2 items-center "
          >
            <button
               id="avatar-signout-button"
              className="flex flex-row gap-2 items-center bg-gray-700 p-2 rounded-md text-white hover:bg-gray-600 "
              onClick={handleSignout}
            >
              <LogOut />
              Signout
            </button>
          </li>
        </ul>
      </div>
    </div>
  )
}

export default Avatar
