import React from "react"
// import { useParams } from "react-router-dom"
import StaticWebsiteInfo from "../../components/StaticWebsiteInfo"
import vars from "../../vars"
const PricingPage = () => {
  // const { tier } = useParams()
  //   switch (tier) {
  //     case "static-site":
  //       return <StaticWebsiteInfo pricingObj={vars.pricing} />

  //     case "e-commerce":
  //       break
  //     case "custom":
  //       break

  //     default:
  //       return <StaticWebsiteInfo />
  //   }
  return <StaticWebsiteInfo pricingObj={vars.pricing} />
}

export default PricingPage
