/**
 * @description Shows Errors from axios ->axios.response.data.error
 * @param {Object} error
 */
const getError = (err) => {
  try {
    return err.response.data.error
  } catch (error) {
    return "there was an error"
  }
}

const getValidationErrors = (err) => {
  try {
    const a = Object.entries(err.response.data.error.errors)[0][1].message
    console.log(a)
    return a
  } catch (error) {
    return "There was an error. Try again"
  }
}

const main = {
  getError,
  getValidationErrors,
}

export default main
