import React from "react"
import { ArrowDown } from "react-feather"
import logo from "../../assets/svg/Logo.svg"
import vars from "../../vars"
const About = () => {
  const top = (
    <div className=" min-h-full pt-40 pb-60">
      <section className="text-gray-600 body-font">
        <div className="container px-5 py-24 mx-auto flex flex-col">
          <div className="lg:w-4/6 mx-auto ">
            <div className="flex flex-col sm:flex-row mt-10">
              <div className="sm:w-1/3 text-center sm:pr-8 sm:py-8">
                <div className="w-20 h-20 rounded-full inline-flex items-center justify-center bg-gray-200 text-gray-400">
                  <img
                    src={logo}
                    alt="alpha beta software"
                    className="shadow-md rounded-full"
                  />
                </div>
                <div className="flex flex-col items-center text-center justify-center">
                  <h2 className="font-medium title-font mt-4 text-gray-900 text-lg">
                    About Us
                  </h2>
                  <div className="w-12 h-1 bg-green-500 rounded mt-2 mb-4"></div>
                  <p className="text-base">{vars.about.whoWeAre.subtitle}</p>
                </div>
              </div>
              <div className="sm:w-2/3 sm:pl-8 sm:py-8 sm:border-l border-gray-200 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left">
                <p className="leading-relaxed text-lg mb-4">
                  {vars.about.whoWeAre.body}
                </p>
                <div className="text-green-400">More info below</div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )

  const questions = (
    <div>
      <p>
        If you have any questions feel free to chat with us at{" "}
        <a
          className="font-bold underline"
          href="mailto: admin@alphabetasoftware.com"
        >
          {vars.about.questions.email}
        </a>
      </p>
    </div>
  )
  const fullstack = (
    <div className="min-h-full" id="about-fullstack">
      <section className="text-gray-600 body-font">
        <div className="container px-5 py-24 mx-auto flex flex-col">
          <div className="lg:w-4/6 mx-auto ">
            <div className="flex flex-col sm:flex-row mt-10">
              <div className="sm:w-1/3 text-center sm:pr-8 sm:py-8">
                <div className="flex flex-col items-center text-center justify-center">
                  <h2 className="font-medium title-font mt-4 text-gray-900 text-lg">
                    {vars.about.moreInfo.title}
                  </h2>
                  <div className="w-12 h-1 bg-green-500 rounded mt-2 mb-4"></div>
                  <p className="text-base">{vars.about.moreInfo.subtitle}</p>
                </div>
              </div>
              <div className="sm:w-2/3 sm:pl-8 sm:py-8 sm:border-l border-gray-200 sm:border-t-0 border-t mt-4 pt-4 sm:mt-0 text-center sm:text-left">
                <p className="leading-relaxed text-lg mb-4">
                  {vars.about.moreInfo.body}
                </p>
                {questions}
                <div className="mt-20 flex flex-col gap-y-5">
                  Or
                  <p>Signup to get let us know what you need</p>
                  <button className="text-center bg-green-500 p-3 rounded-xl text-white w-40  ">
                    Signup
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
  return (
    <>
      {top}
      <div className="services  justify-center items-center bg-gray-100 p-12 flex flex-col">
        <h2 className="text-2xl">More info about our Services</h2>
        <div className="border border-black rounded-full p-2 mt-5">
          <ArrowDown className="animate-bounce" />
        </div>
      </div>
      {fullstack}
    </>
  )
}

export default About
