import React, { useState } from "react"
import { createSetupIntent } from "../../API/stripe/customer"
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js"
import { useHistory } from "react-router-dom"

const userInfo = {
  name: "",
  email: "",
}

const userAddress = {
  street: "",
  city: "",
  country: "",
  zip: "",
  state: "",
}



// const userCard = {
//   cvc: "",
//   exp_month: "",
//   exp_year: "",
//   focus: "",
//   name: "",
//   number: "",
// }

export const UserDashboardContext = React.createContext({})
const UserDashboardState = ({ children }) => {
  const [info, setInfo] = useState(userInfo)
  const [address, setAddress] = useState(userAddress)
  const [paymentMethods, setPaymentMethods] = useState()
  // const [card, setCard] = useState(userCard)
  const stripe = useStripe()
  const elements = useElements()
  const hist = useHistory()

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!stripe || !elements) return //disable form submision until ready

    if (
      info.name &&
      info.email &&
      address.street &&
      address.city &&
      address.country &&
      address.zip
    ) {
      await createSetupIntent()
        .then(async (x) => {
          const { client_secret } = x.data
          console.log(client_secret)
          return await stripe
            .confirmCardSetup(client_secret, {
              payment_method: {
                card: elements.getElement(CardElement),
                billing_details: {
                  address: {
                    line1: address.street,
                    city: address.city,
                    state: address.state, //need to impliment in card page
                    country: address.country,
                    postal_code: address.zip,
                  },
                  name: info.name,
                  email: info.email,
                },
              },
            })
            .then((cardSetup) => {
              console.log(cardSetup)
              hist.go(0)
            })
        })
        .catch((error) => {
          console.log(error)
        })
    } else {
      alert("please fill in all the information")
    }
  }
 
  return (
    <UserDashboardContext.Provider
      value={{
        info,
        setInfo,
        address,
        setAddress,
        paymentMethods,
        setPaymentMethods,
        // card,
        // setCard,
        handleSubmit,
      }}
    >
      {children}
    </UserDashboardContext.Provider>
  )
}

export default UserDashboardState
