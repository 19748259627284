import React from "react"
import { Settings } from "react-feather"
import { Link } from "react-router-dom"
import vars from "../../vars"

const DashboardTemplate = ({ children }) => {
  return (
    <div className="w-full flex flex-row bg-gray-100 h-auto">
      <div className="  dashboard-side-bar bg-gray-700 flex flex-col pt-60 md:pt-40  w-full h-auto  bg-opacity-70 backdrop-filter backdrop-blur-md">
        {vars.userDashboard.menuItems.map((x, i) => {
          return (
            <Link to={`/dashboard${x[2]}`} key={`${x}${i}`}>
              <div className="hover-item h-10 p-2 flex flex-row ml-4 rounded-l-lg">
                <div> {x[1]}</div>
                <div className="pl-4 ml-6 whitespace-pre"> {x[0]}</div>
              </div>
            </Link>
          )
        })}
        <div className="mt-auto text-white mx-auto mb-3">
          <Link to="/dashboard/settings">
            <Settings className="rotate" />
          </Link>
        </div>
      </div>
      <div className="mt-52 mx-auto">
        <h1 className="text-center px-4 py-8 text-2xl uppercase">Dashboard</h1>
        <>{children}</>
      </div>
    </div>
  )
}

export default DashboardTemplate
