import ForgotPassword from "../components/ForgotPassword"
import Signup from "../components/Signup"
import About from "../Pages/About"
// import Cart from "../Pages/Cart"
import Invoices from "../Pages/Dashboard/Invoices"
import CardPage from "../Pages/Dashboard/Pages/CardPage"
import DashboardHome from "../Pages/Dashboard/Pages/DashboardHome"
import Order from "../Pages/Dashboard/Pages/Order"
import Settings from "../Pages/Dashboard/Pages/Settings"
import FAQ from "../Pages/FAQ"
import Home from "../Pages/Home"
import PasswordReset from "../Pages/PasswordReset"
import Profile from "../Pages/Profile"
import SignInPage from "../Pages/SignInPage"

const publicRoutes = [
  {
    name: "Home",
    path: "/",
    component: <Home />,
  },
  {
    name: "FAQ",
    path: "/faq",
    component: <FAQ />,
  },
  {
    name: "About",
    path: "/about",
    component: <About />,
  },
]

const privateRoutes = [
  {
    name: "Dashboard",
    path: "/dashboard",
    component: <DashboardHome />,
  },
  {
    name: "Profile",
    path: "/profile",
    component: <Profile />,
  },
  {
    name: "Wallet",
    path: "/dashboard/wallet",
    component: <CardPage />,
  },
  {
    name: "Settings",
    path: "/dashboard/settings",
    component: <Settings />,
  },
  {
    name: "Order",
    path: "/dashboard/order",
    component: <Order />,
  },
  {
    name: "Invoices",
    path: "/dashboard/invoices",
    component: <Invoices />,
  },
]

//these will show up in navbar
const authRoutes = [
  {
    name: "Signin",
    path: "/signin",
    component: <SignInPage />,
    id:"header-link-signin"
  },
  {
    name: "Sign up",
    path: "/signup",
    component: <Signup />,
    id:"header-link-signup"
  },
]

//these wont show up in the navigation (navbar) of the page
const authRoutesNoHeaderLink = [
  {
    name: "Forgot Password",
    path: "/forgot-password",
    component: <ForgotPassword />,
  },
  {
    name: "Password Reset",
    path: "/password-reset/:jwt",
    component: <PasswordReset />,
  },
]

const authRoutesNoDash = []

const main = {
  publicRoutes,
  privateRoutes,
  authRoutes,
  authRoutesNoDash,
  authRoutesNoHeaderLink,
}

export default main
