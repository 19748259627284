import React from "react"
import { useHistory } from "react-router-dom"
import stripeAPI from "../../../../API/stripe"
import invoiceImageRed from "../../../../assets/svg/reciept-red.svg"
import invoiceImage from "../../../../assets/svg/reciept.svg"
const Invoice = ({
  amountDue,
  amountPaid,
  currency,
  description,
  total,
  id,
  hostedInvoiceUrl,
  lines,
}) => {
  const hist = useHistory()
  const paid = (due, paid) => due - paid === 0

  const chip = (text, color = "green") => (
    <div
      className={`self-center w-40 flex justify-center items-center m-1 font-medium py-1 px-2 bg-white rounded-full text-${color}-700 bg-${color}-100 border border-${color}-300 `}
    >
      <div className="text-xs font-normal leading-none max-w-full flex-initial">
        {text}
      </div>
    </div>
  )

  const handlePay = async () => {
    //call invoice pay api here

    await stripeAPI.invoices
      .pay(id)
      .then((x) => {
        //check if x has data
        const { message } = x.data
        if (message) {
          alert(message)
          hist.go(0)
        }
      })
      .catch((x) => {
        //redirect off site

        if (hostedInvoiceUrl) {
          window.open(hostedInvoiceUrl, "_blank")
        } else if (!hostedInvoiceUrl) {
          alert(
            "Your invoice is being created. Please wait a few minutes and try again."
          )
        }
      })
  }

  const card = (description) => {
    return (
      <div className="max-w-xs mx-auto overflow-hidden bg-white rounded-lg shadow-lg dark:bg-gray-800 ">
        <div className="px-4 py-2">
          <h1 className="text-3xl font-bold text-gray-800 uppercase dark:text-white">
            Invoice
          </h1>

          <p className="mt-1 text-sm text-gray-600 dark:text-gray-400">
            {lines?.data?.length > 0
              ? lines.data[0].description
              : "Thank you for your business"}
          </p>
          <p className="mt-1 text-sm text-gray-600 dark:text-gray-400"></p>
        </div>

        <img
          className="object-cover "
          src={paid(amountDue, amountPaid) ? invoiceImage : invoiceImageRed}
          alt="NIKE AIR"
        />

        <div className="flex items-center justify-between px-4 py-2 bg-gray-900 ">
          {paid(amountDue, amountPaid)
            ? chip(`Paid $${total / 100} ${currency.toUpperCase()}`)
            : chip(`$${amountDue / 100} ${currency.toUpperCase()}`, "red")}

          {paid(amountDue, amountPaid) === false ? (
            <button
              className="px-2 py-1 text-xs font-semibold text-gray-900 uppercase transition-colors duration-200 transform bg-white rounded hover:bg-gray-200 focus:bg-gray-400 focus:outline-none"
              onClick={handlePay}
            >
              Pay now
            </button>
          ) : (
            <p classNameName="text-white">Thank you!</p>
          )}
        </div>
      </div>
    )
  }

  return <>{card(description)}</>
}

export default Invoice
