import React, { useContext } from "react"
import { Link, useHistory } from "react-router-dom"
import { GlobalContext } from "../../contexts/GlobalState"
import routes from "../../routes"
import appInfo from "../../vars.js"
import Avatar from "../Avatar"

const Header = () => {
  const globalCtx = useContext(GlobalContext)
  const hist = useHistory()
  // const handleSignout = async () => {
  //   await globalCtx.auth.logout()
  // }
  const hoverColor = "green"
  return (
    <header className="text-black body-font bg-black  w-full   bg-opacity-70 backdrop-filter backdrop-blur-md items-center justify-center fixed z-50">
      <div className="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
        <div className="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0">
          <svg
            width="50"
            height="50"
            viewBox="0 0 474 474"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M197.5 289L209.5 309.5L217.357 304.5L216 287.5C218 284.5 223.6 274.2 230 257C226.5 235 232 238.5 236 234.5C239.2 231.3 255.667 238.5 263.5 242.5H270.5C270.526 258.222 270.685 267.06 269 282.5L273 283L273.25 290.75L256.5 289L220.5 302.5L217.357 304.5L209.5 309.5H186.5L182 316.5L158 332.5L102.5 320L90 332.5L83.5 341V355.5L79 376.5L149.5 430L246.5 450L356 414L420 345.5L374.5 325H359.5L332.5 307L327.5 309.5L321 302.5L310 299L293.5 292.5L290 283L297 251.5V243.5L304 226.5C304.5 222.833 305.7 213.4 306.5 205C313 197 309 193.5 314 189.5C318 186.3 322.667 188.833 324.5 190.5L337 189.5L343 182.5L329.5 172V166.5L321.5 156.5V153C323.333 150.833 327.1 146.2 327.5 145C327.9 143.8 326.333 143.5 325.5 143.5L321 147.5C317.833 150.167 310.1 155.7 304.5 156.5C298.9 157.3 287.167 172.5 282 180L273 187.5H268L256.5 195C248.5 196.5 228.1 201 210.5 207C192.9 213 184.167 230.5 182 238.5C180.333 242.333 176.6 251 175 255C173.4 259 179 259.667 182 259.5L190.5 245C193.159 258.664 195.725 259.457 199.5 266C199.658 274.908 199.902 279.889 197.5 289ZM282 284H280L278.5 280L280.5 256L282.5 239.5L284.5 239L283.5 243.5L282 284Z"
              fill="white"
            />
            <circle cx="237" cy="237" r="221" stroke="white" strokeWidth="32" />
          </svg>
          <span className="ml-3 text-xl text-white">
            {appInfo.company.name}
          </span>
        </div>
        <nav className="md:ml-auto md:mr-auto flex flex-wrap items-center text-base justify-center">
          {routes.publicRoutes.map((x, i) => {
            return (
              <Link
                id={`header-link-${x.path}`}
                key={`${i}-${x.name}`}
                to={x.path}
                className={`mr-5 hover:text-${hoverColor}-500 text-white`}
              >
                {x.name}{" "}
              </Link>
            )
          })}
          {/* if isAuth === false show signin/up  routes. */}
          {globalCtx.auth.isAuth() === false ? (
            routes.authRoutes.map((x, i) => (
              <Link
                id={x.id}
                key={`${i}-${x.name}`}
                to={x.path}
                className={`mr-5 hover:text-${hoverColor}-500 text-white `}
              >
                {x.name}{" "}
              </Link>
            ))
          ) : (
            <Link
              id={`header-link-/dashboard`}
              to={routes.privateRoutes[0].path}
              className={`mr-5 hover:text-${hoverColor}-500 text-white`}
            >
              {routes.privateRoutes[0].name}
            </Link>
          )}{" "}
          {/*add dashboard here*/}
        </nav>
        {/* Signout */}

        {/* cart */}
        {/* <div className="relative">
          <>
            <ShoppingCart
              className="text-white m-5 cursor-pointer relative"
              onClick={() => {
                hist.push("/cart")
              }}
            />
            {globalCtx.cart.length > 0 ? (
              <div className="flex items-center justify-center absolute rounded-full w-6 h-6 text-white bg-green-400 left-10 bottom-10 text-center p-1">
                <div>{globalCtx.cart.length}</div>
              </div>
            ) : null}
          </>
        </div> */}

        {/* Avatar */}
        {globalCtx?.auth?.isAuth() !== false ? <Avatar /> : null}
      </div>
    </header>
  )
}

export default Header
