import React, { createContext, useEffect, useState } from "react"
import Snackbar from "../../components/Snackbar"

export const SnackbarContext = createContext()

const AUTO_DISMISS = 10000

function SnackbarProvider({ children }) {
  const [alerts, setAlerts] = useState([])
  const [variants, setVariants] = useState([])

  const activeAlertIds = alerts.join(",")
  useEffect(() => {
    if (activeAlertIds.length > 0) {
      const timer = setTimeout(
        () => setAlerts((alerts) => alerts.slice(0, alerts.length - 1)),
        AUTO_DISMISS
      )
      return () => clearTimeout(timer)
    }
  }, [activeAlertIds])

  const addAlert = (text, variant) => {
    setAlerts((alerts) => [text, ...alerts])
    setVariants((variants) => [variant, ...variants])
  }

  return (
    <SnackbarContext.Provider value={{ addAlert }}>
      {children}
      {alerts.map((item, i) => (
        <Snackbar key={item} text={item} variant={variants[i]} />
      ))}
    </SnackbarContext.Provider>
  )
}

export default SnackbarProvider
