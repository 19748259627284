import React from "react"
import { ArrowDownCircle } from "react-feather"
import { Link } from "react-router-dom"
import codeImage from "../../assets/img/code2.png"

const StaticWebsiteInfo = ({ pricingObj }) => {
  return (
    <div
      id="pricing-more-info"
      className=" justify-center items-center md:p-40 "
    >
      {pricingObj?.moreInfo.map((x, i) => {
        return (
          <div
            id={x.name.toLowerCase()}
            className={`bg-gray-100 p-4 shadow-md border-top-100 `}
          >
            <h2 className="text-4xl sm:m-20 sm:pt-20  text-center sm:text-left pt-40 ">
              {x.name} Websites
            </h2>
            <section className="text-gray-600 body-font flex flex-col items-center justify-center md:flex-row p-6 ">
              {x.icon}

              <div className="container px-5 py-24 mx-auto">
                <div className="xl:w-1/2 lg:w-3/4 w-full mx-auto text-center">
                  <h2 className="text-gray-900  title-font tracking-wider text-bold text-2xl ">
                    {x.name}
                  </h2>
                  <span className="inline-block h-1 w-10 rounded bg-green-500 mt-8 mb-6"></span>
                  <p className="text-gray-500">{pricingObj.tiers[i].price}</p>
                  <p className="leading-relaxed text-lg">{x.description}</p>
                </div>
              </div>
            </section>
            {/* How we make websites */}
            <section className="text-gray-600 body-font ">
              <h2 className=" text-gray-900  title-font tracking-wider text-bold text-4xl lg:w-2/6 md:w-3/6 w-5/6 mb-10 w-40 h-40  p-6 object-cover object-center sm:m-20">
                How We Make {x.name} Websites
              </h2>
              <div className="container px-5 py-24 mx-auto flex flex-wrap">
                <div className="flex flex-wrap w-full">
                  <div className="lg:w-2/5 md:w-1/2 md:pr-10 md:py-6">
                    {x.steps.map((x, i) => (
                      <div className={`flex relative pb-12 `}>
                        <div className="h-full w-10 absolute inset-0 flex items-center justify-center">
                          <div className="h-full w-1 bg-gray-200 pointer-events-none"></div>
                        </div>

                        <div
                          className={`flex-shrink-0 w-10 h-10 rounded-full shadow-lg bg-${
                            i % 2 === 0 ? "green" : "indigo"
                          }-500 inline-flex items-center justify-center text-white relative z-10`}
                        >
                          <ArrowDownCircle />
                        </div>

                        <div
                          className={`flex-grow pl-4 ${x.color} rounded-xl p-3`}
                        >
                          <h2
                            className={`font-medium title-font text-sm text-gray-900 mb-1 tracking-wider `}
                          >
                            STEP {i + 1}
                          </h2>
                          <p className={`leading-relaxed`}>{x.description}</p>
                        </div>
                      </div>
                    ))}

                    <div className="flex relative">
                      <div className="flex-grow pl-4">
                        <h2 className="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">
                          FINISH
                        </h2>
                        <p className="leading-relaxed">
                          Show project to clients
                        </p>
                        <h2 className="font-medium title-font text-sm text-gray-900 mb-1 tracking-wider">
                          ETA: 5-10 weeks
                        </h2>
                      </div>
                    </div>
                  </div>
                  <img
                    className="lg:w-3/5 md:w-1/2 shadow-lg object-cover object-center rounded-lg md:mt-0 mt-12"
                    src={codeImage}
                    alt="step"
                  />
                </div>
              </div>
            </section>

            {/* end */}
            <div className="flex flex-col items-start gap-4 ">
              <Link to="/signup">
                <button
                  className={`border p-2 rounded border-${
                    i % 2 === 0 ? "indigo" : "green"
                  }-500 text-${i % 2 === 0 ? "indigo" : "green"}-500`}
                >
                  {" "}
                  Start your journey and sign up
                </button>
              </Link>

              <Link to="/faq" className="text-black-900" href="/signup">
                Questions? See our FAQ
              </Link>
            </div>
          </div>
        )
      })}
    </div>
  )
}

export default StaticWebsiteInfo
