
import axios      from "axios"

import { isAuth } from "../Auth/email"
const base = `${process.env.REACT_APP_API}/stripe/card`




/**
 * @description save user's card information
 * @returns 
 */
export const save = async (
	number,
	exp_month,
	exp_year,
	cvc,
	password
) => {
	const url = `${base}/save-card`
	const jwt = isAuth("jwt")
	if (!jwt) {
		
		return "error"
	}
	return await axios.post(
		url,
		{
			card: {
				number,
				exp_month,
				exp_year,
				cvc,
			},
			password,
		},
		{
			headers: {
				Authorization: `Bearer ${jwt}`,
			},
			withCredentials: true,
		}
	)
}

/**
 * 
 * @param {String} customerStripeId 
 * @returns StripePaymentMethod
 */
export const get = async (
	customerStripeId
) => {
	const url = `${base}/get-card`
	const jwt = isAuth("jwt")
	if (!jwt) {
		
		return "error"
	}
	return await axios.post(
		url,
		{
			stripeId:customerStripeId
		},
		{
			headers: {
				Authorization: `Bearer ${jwt}`,
			},
			withCredentials: true,
		}
	)
}


/**
 * 
 * @param {String} number cardNumber
 * @param {Number} exp_month 
 * @param {Number} exp_year 
 * @param {Number} cvc 
 * @returns success? message : error
 */
export const update = async (
	number,
	exp_month,
	exp_year,
	cvc,
) => {
	const url = `${base}/save-card`
	const jwt = isAuth("jwt")
	if (!jwt) {
		
		return "error"
	}
	return await axios.post(
		url,
		{
			card: {
				number,
				exp_month,
				exp_year,
				cvc,
			},
		},
		{
			headers: {
				Authorization: `Bearer ${jwt}`,
			},
			withCredentials: true,
		}
	)
}

/**
 * @description gets all payment Methods
 */
 export const getAllPaymentMethods = async () => {
	const url = `${base}/get-payment-methods`
	const jwt = isAuth()
	return await axios.post(
	  url,
	  {},
	  {
		headers: {
		  Authorization: `Bearer ${jwt}`,
		},
		withCredentials: true,
	  }
	)
}
  
/**
 * @description gets all payment Methods
 */
 export const deleteCard = async (pm_id) => {
	const url = `${base}/delete-payment-method`
	const jwt = isAuth()
	return await axios.post(
	  url,
	  {pm_id},
	  {
		headers: {
		  Authorization: `Bearer ${jwt}`,
		},
		withCredentials: true,
	  }
	)
  }
  
  

const main = {
	save,
    get,
    update
}

export default main