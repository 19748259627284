import React from "react"

const TierMiniCard = ({ name, description, src }) => {
  return (
    <>
      <div className="max-w-xs mx-auto overflow-hidden bg-white rounded-lg shadow-lg dark:bg-gray-800">
        <img className="object-cover w-full h-56" src={src} alt="avatar" />

        <div className="py-5 text-center flex flex-col">
          <div>{name}</div>

          <span className="text-sm text-gray-700 dark:text-gray-200">
            {description}
          </span>
        </div>
      </div>
    </>
  )
}

export default TierMiniCard
